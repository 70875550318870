.section-repeat {
    max-width: 1300px;
    /* margin: 0 auto; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.section-repeat__empty-wordArr {
    display: block;
    width: 100%;
    text-align: center;
}

@media screen and (max-width:760px) {

    /* .section-repeat__empty-wordArr {
        min-height: calc(100vh - 25px - 40px);
    } */

    .section-repeat {
      /* min-height: calc(100vh - 25px - 40px); */
      justify-content: center;
    }
}   