/* WordForm */

.add-category-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 80vh;
  }
  
  .add-category-section__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 430px;
    background-color: #fff;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 34px 36px;
    -webkit-box-shadow: 0px 0px 8px 5px rgba(21, 106, 171, 0.3);
    -moz-box-shadow: 0px 0px 8px 5px rgba(21, 106, 171, 0.3);
    box-shadow: 0px 0px 8px 5px rgba(21, 106, 171, 0.3);
    position: relative;
  }
  
  .add-category-section__title {
    margin: 0 0 15px;
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    color: #1A1B22;
  }
  
  .form__select {
    width: 100%;
    height: 45px;
    border: none;
    border-bottom: 1px solid  rgba(0, 0, 0, .2);
    font-family: Inter;
    font-size: 18px;
    line-height: 17px;
    box-sizing: border-box;
    outline-color: transparent;
  }
  
  .form__select:focus {
    outline: none;
  }
  
  @media screen and (max-width:760px) {

    .add-category-section{
      /* height: calc(100vh - 25px - 40px); */
      padding: 0 10px;
    }

    .add-category-section__content {
      padding: 10px 10px;
    }
}