footer {
    max-width: 1300px; 
    width: 100%;
    display: flex;
    flex-direction: row;
    background: cornflowerblue;
    position: fixed;
    bottom: 0;
    /* left: 0px;
    right: 0; */
    /* top: 0; */
    justify-content: center;
    height: 25px;
    align-items: center;
    /* margin-top: auto; */
    /* flex-shrink: 0;   */
  }

  .footer__copirate {
    margin-right: 20px;
  }

  .footer__link_main {
    margin-right: 20px;
  }