
.title-section {
    display: flex;
    flex-direction: column;
    width: 70%;
    /* margin: 40px 0 0; */
}

.title-section__title {
    font-size: 48px;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 10px;
}

.title-section__description {
    margin: 0 0 10px;
    font-size: 18px;
    color: #999999;
    margin-top: 25px;
    line-height: 2;
    font-weight: 600;
}

.instrustion-section {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.instrustion-section__title {
    font-size: 36px;
    color: #1a1a1a;
    font-weight: 600;
    margin-bottom: 25px;
    margin-top: 75px;
}

.instrustion-section__items {
    display: flex;
    flex-direction: row;
    flex-basis: 250px;
    justify-content: space-around;
    flex-wrap: wrap;
}

.item {
    width: 250px;
    margin-top: 10px;
    box-shadow: 3px 3px 1px 1px rgba(0, 0, 255, .2);
    height: 150px;
}

.item__title {
    font-size: 24px;
    color: #1a1a1a;
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: 500;
    display: block;
    text-align: center;
}

.item__paragraph {
    font-size: 18px;
    color: #999999;
    margin-bottom: 70px;
    margin: 0 0 10px;
    text-align: center;
}

@media screen and (max-width:760px) {

    .main-page {
        padding: 0 10px;
    }

    .title-section {
        display: flex;
        flex-direction: column;
        width: 90%;
    }

    .title-section__title {
        font-size: 24px;
        font-weight: 250;
        /* margin-top: 20px;
        margin-bottom: 5px; */
    }

    .instrustion-section__title {
        font-size: 18px;
        color: #1a1a1a;
        font-weight: 300;
        margin-bottom: 13px;
        margin-top: 10px;
    }
    
    .instrustion-section__items {
        display: flex;
        flex-direction: row;
        flex-basis: 150px;
        justify-content: space-around;
        flex-wrap: wrap;
        margin: 0 0 10px;
    }
    
    .item {
        width: 150px;
        margin-top: 5px;
        box-shadow: 1px 1px 1px 1px rgba(0, 0, 255, .2);
        height: 75px;
    }
    
    .item__title {
        font-size: 12px;
        color: #1a1a1a;
        margin-top: 10px;
        margin-bottom: 5px;
        font-weight: 250;
        display: block;
        text-align: center;
    }
    
    .item__paragraph {
        font-size: 9px;
        color: #999999;
        margin-bottom: 30px;
        margin: 0 0 5px;
        text-align: center;
    }
}
