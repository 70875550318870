html {
  /* overflow-y: scroll; */
  height: 100%;
  max-width: 1300px;
  /* position: relative; */
  margin: 0 auto;
  height: -webkit-fill-available;
}


a {
  text-decoration: none;
  color: aliceblue;
}

fieldset {
  border: 0;
  padding: 0.01em 0 0 0;
  margin: 0;
  min-width: 0;
}

* {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

body {
  /* max-width: 1440px; */
  margin: 0;
  /* height: 100%; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  /* display: flex; */
  /* flex-direction: column; */
  /* position: relative; */
}

#root {
  margin: 0;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  /* min-height: calc(100vh);
  min-height: -webkit-fill-available; */
}

.commonClass {
  flex: 1;
  margin-bottom: 25px !important;
  margin-left: 10px;
  margin-right: 10px;
}

a {
  outline: none;
  text-decoration: none;
  padding: 2px 1px 0;
}

a:link {
  color: #0059ff;
}

/* a:visited {
  color: #437a16;
} */

/* a:focus {
  border-bottom: 1px solid;
  background: #bae498;
} */

/* a:hover {
  border-bottom: 1px solid;
  background: #cdfeaa;
} */

a:active {
  background: #265301;
  color: #cdfeaa;
}