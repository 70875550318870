
.section-card {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 10px;
    box-sizing: border-box;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    padding: 34px 36px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 8px 5px rgba(21, 106, 171, 0.3);
    -moz-box-shadow: 0px 0px 8px 5px rgba(21, 106, 171, 0.3);
    box-shadow: 0px 0px 8px 5px rgba(21, 106, 171, 0.3);
}

.card__title {
    margin: 0 0 15px;
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    color: #1A1B22;
    text-align: center;
}

.card__word {
    display: flex;
    width: 100%;
    height: 40px;
    align-items: center;
    justify-content: center;
    margin: 25px 0;
    border-top: 1px solid rosybrown;
    border-bottom: 1px solid rosybrown;
    text-transform: uppercase;
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-size: 18px;
  text-align: center;
}

.card__right-count {
  font-family: Roboto, Arial, Helvetica, sans-serif;
}

.card__wrong-count {
  font-family: Roboto, Arial, Helvetica, sans-serif;
}

.card__word_write {
    margin: 25px 0 10px;
}

.card__container-button {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.word-check {
  padding: 10px;
  flex-direction: column;
  width: auto;
}

.card__hint {
  margin: 10px;
}

.card-button {
    min-width: 250px;
    height: 40px;
    border: 1px blue solid;
    background: none;
    cursor: pointer;
    margin: 15px 15px;
    text-transform: uppercase;
    color: black;
}

.button__change-test {
  margin: 10px;
  height: 30px;
  width: 180px;
  background: transparent;
  color: black;
}

@media screen and (max-width:760px) {

  .card {
    padding: 15px;
    width: 90%;
  }

  .card__word {
    margin: 5px 0;
  }
.card__word {
  font-size: 15px;
  height: 30px;
}

.card-button {
  margin: 5px 0;
  font-size: 15px;
  height: 30px;
}

}   