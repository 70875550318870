/* Loader */

.preloader-content {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    /* background-color: black; */
    z-index: 1;
    /* opacity: 0.1; */
}

.preloader {
	margin: 0;
	min-height: 20px;
  	background: #F5F6F7;
	overflow: hidden;
}

.circle-preloader {
	display: block;
	width: 50px;
	height: 50px;
	border: 4px solid #444;
	border-bottom-color: #888;
	border-radius: 50%;
	margin: auto;
	animation: spin .75s infinite linear;
}

@keyframes spin {
	100% {
		transform: rotate(360deg);
	}
}
