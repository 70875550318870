.section-main {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    max-width: 1300px;
    padding: 15px 0;
    box-sizing: border-box;
    position: relative;
}

.section-nav-tasks {
    /* position: fixed; */
    max-width: 250px;
    /* top: 25px; */
}

.main-content {
    width: 100%;
}

.input_tasks {
    height: 39px;
    border: none;
    outline: none;
    width: 200px;
    padding-left: 10px;
    margin-bottom: 8px;
}

.nav-themes {
    width: 200px;
}

.nav__items_tasks {
    flex-direction: column;
    background: cornflowerblue;
}

.nav__item-li_tasks {
    border-bottom: 1px #9e9e9e solid;
}

.nav__item-button_tasks {
    border: 1px solid white;
    display: block;
    cursor: pointer;
    font-style: normal;
    font-size: 18px;
    color: #B6BCBF;
    background: #E6E8EB;
    background: #2F71E5;
    width: 100%;
    padding: 8px;
}

.menu__btn {
    display: flex;
    align-items: center;
    position: fixed;
    width: 26px;
    height: 26px;
    cursor: pointer;
    z-index: 1;
}

.menu__btn>span,
.menu__btn>span::before,
.menu__btn>span::after {
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #616161;
    transition-duration: .25s;
}

.menu__btn>span::before {
    content: '';
    top: -8px;
}

.menu__btn>span::after {
    content: '';
    top: 8px;
}

.menu__box {
    display: block;
    position: fixed;
    visibility: hidden;
    top: 0;
    left: -100%;
    width: 300px;
    height: 100%;
    margin: 0;
    padding: 80px 0;
    list-style: none;
    background-color: #ECEFF1;
    box-shadow: 1px 0px 6px rgba(0, 0, 0, .2);
    transition-duration: .25s;
}

.menu__item {
    display: block;
    padding: 12px 24px;
    color: #333;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 600;
    text-decoration: none;
    transition-duration: .25s;
}

.menu__item:hover {
    background-color: #CFD8DC;
}

@media screen and (max-width:760px) {

    .section-main {
        margin: 0;
        padding: 10px 0 0;
    }
}   